<template>
  <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header card-header-fixed border-0 px-6">
            <div class="row align-items-center col-8 px-0">
                  <div class="col-4 pr-0">
                    <div class="input-icon">
                      <input 
                        type="text" 
                        class="form-control form-control-solid" 
                        :placeholder="$t('mealsystem.placeholder.search')"
                        v-model="search"
                        >
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
            
                  <b-button
                    variant="secondary"
                    class="mr-3 ml-3 pr-2" @click.prevent="removeFilters">
                    <i class="fa fa-times icon-md"></i>
                  </b-button>
                  <b-button
                    variant="primary" @click.prevent="searchContributors">
                  {{ $t("button.filter") }}   
                  </b-button>

            </div>
            <div class="card-toolbar">
                <span class="btn btn-success" v-b-modal.modal-contributor-edit @click="usetContributor()">
                  <span class="svg-icon svg-icon-md svg-icon-white">
                    <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                  </span>
                  {{ $t("mealsystem.contributors.button.create") }}
                </span>
              </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Card-->
    <div class="card card-custom" >
      <!--begin::Body-->
      <div class="card-body">
        <div class="table-responsive ">
          <table class="table table-head-custom table-head-bg table-vertical-center">
            <thead>
              <tr class="text-left text-uppercase">
                <th width="20"></th>
                <th>{{ $t("mealsystem.contributors.label.name") }}</th>
                <th>{{ $t("mealsystem.contributors.label.email") }}</th>
                <th>{{ $t("mealsystem.contributors.label.phone") }}</th>
                <th width="20"></th>
              </tr>
            </thead>
            <tbody v-if="!loading">
                <tr v-for="(contributor, index) in contributors.data" :key="index">
                  <td class="pl-0">
                    <a class="btn btn-link btn-sm btn btn-clean btn-hover-light-primary btn-sm btn-icon" v-b-tooltip.right="$t('button.settings')" v-b-modal.modal-contributor-edit @click="loadContributor(contributor)"><i class="ki ki-gear icon-md"></i></a>
                  </td>
                  <td>
                    <span class="font-weight-bold">{{ contributor.name }}</span>
                  </td>
                  <td>
                    <span v-if="contributor.email">{{ contributor.email }}</span>
                    <span v-else class="text-muted">-</span>
                  </td>
                  <td>
                    <span v-if="contributor.phone">{{ contributor.phone }}</span>
                    <span v-else class="text-muted">-</span>
                  </td>
                  <td>
                    <a class="btn btn-link btn-sm btn btn-clean btn-hover-light-danger btn-sm btn-icon" v-b-tooltip.left="$t('mealsystem.contributors.title.delete_contributor')" v-b-modal.modal-contributor-delete @click="setContributor(contributor)"><i class="ki ki-bold-close icon-sm text-danger"></i></a>
                  </td>
                </tr>
            </tbody>
          </table>
    
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->

    <b-modal id="modal-contributor-delete" :okTitle="$t('button.delete')" okVariant="danger" :title="$t('mealsystem.contributors.title.confirm_delete_contributor_title')">
      <span v-html="$t('mealsystem.contributors.title.confirm_delete_contributor_text')"></span>
        <template #modal-footer="{ cancel }">
            <b-button variant="secondary" @click="cancel()" class="mr-3">
              {{ $t('button.cancel') }}
          </b-button> 
          <b-button variant="danger" @click="deleteContributor(selectedContributor)">
            {{ $t('mealsystem.contributors.button.delete_contributor') }}
          </b-button>         
        </template>
    </b-modal>

  <b-modal id="modal-contributor-edit" size="lg" :okTitle="$t('button.save')" okVariant="success" :title="$t('mealsystem.contributors.title.edit_contributor')">
      <ValidationObserver ref="form">

          <div class="d-flex">
                <div class="col-12">
                    <span class="mb-2 d-block">
                        <h3 class="font-size-lg font-weight-bolder mb-3 text-primary">
                            <span class="svg-icon svg-icon-md svg-icon-primary mr-1">
                                <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                            </span>
                            {{ $t('mealsystem.contributors.label.name') }}
                        </h3>    
                    </span>  
                    <div>
                        <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                        <input 
                                name="contributor.name"
                                id="contributor.name"
                                type="text" 
                                class="form-control form-control-lg form-control-solid"
                                :placeholder="$t('mealsystem.contributors.placeholder.name')"
                                v-model="selectedContributor.name"
                        >                
                        <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
          <hr />

          <div class="d-flex align-items-top">
              <div class="col-6 pr-7">
                
                  <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="contributor.in">{{ $t("mealsystem.contributors.label.in") }}</label>
                    <div class="col-lg-5 col-xl-7">            
                        <ValidationProvider>
                        <input 
                            name="contributor.in"
                            id="contributor.in"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.contributors.placeholder.in')"
                            v-model="selectedContributor.in"
                            >    
                        </ValidationProvider>    
                    </div>
                    <div class="col-lg-4 col-xl-3">
                        <button class="col-xl-12 py-3 btn btn-primary" @click.prevent="loadAresData" ref="load_ares">{{ $t("button.load") }}</button>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="contributor.tin">{{ $t("mealsystem.contributors.label.tin") }}</label>
                    <div class="col-lg-5 col-xl-10">            
                        <ValidationProvider>
                        <input 
                            name="contributor.tin"
                            id="contributor.tin"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.contributors.placeholder.tin')"
                            v-model="selectedContributor.tin"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>

                <hr class="mb-7" />

                <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="contributor.email">{{ $t("mealsystem.contributors.label.email") }}</label>
                    <div class="col-lg-5 col-xl-10">            
                        <ValidationProvider>
                        <input 
                            name="contributor.email"
                            id="contributor.email"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.contributors.placeholder.email')"
                            v-model="selectedContributor.email"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="contributor.phone">{{ $t("mealsystem.contributors.label.phone") }}</label>
                    <div class="col-lg-5 col-xl-10">            
                        <ValidationProvider>
                        <input 
                            name="contributor.phone"
                            id="contributor.phone"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.contributors.placeholder.phone')"
                            v-model="selectedContributor.phone"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>

              </div>
              <div class="col-6 flex-column border-left d-flex pl-7">

                <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="contributor.street">{{ $t("mealsystem.contributors.label.street") }}</label>
                    <div class="col-lg-5 col-xl-10">            
                        <ValidationProvider>
                        <input 
                            name="contributor.street"
                            id="contributor.street"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.contributors.placeholder.street')"
                            v-model="selectedContributor.street"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="contributor.house_number">{{ $t("mealsystem.contributors.label.house_number") }}</label>
                    <div class="col-lg-5 col-xl-10">            
                        <ValidationProvider>
                        <input 
                            name="contributor.house_number"
                            id="contributor.house_number"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.contributors.placeholder.house_number')"
                            v-model="selectedContributor.house_number"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="contributor.city">{{ $t("mealsystem.contributors.label.city") }}</label>
                    <div class="col-lg-5 col-xl-10">            
                        <ValidationProvider>
                        <input 
                            name="contributor.city"
                            id="contributor.city"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.contributors.placeholder.city')"
                            v-model="selectedContributor.city"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-xl-2 col-lg-3 col-form-label text-right" for="contributor.zip">{{ $t("mealsystem.contributors.label.zip") }}</label>
                    <div class="col-lg-5 col-xl-10">            
                        <ValidationProvider>
                        <input 
                            name="contributor.zip"
                            id="contributor.zip"
                            type="text" 
                            class="form-control form-control-lg form-control-solid"
                            :placeholder="$t('mealsystem.contributors.placeholder.zip')"
                            v-model="selectedContributor.zip"
                            >    
                        </ValidationProvider>    
                    </div>
                </div>

              </div>
          </div>

      </ValidationObserver>
        <template #modal-footer="{ cancel }">
            <b-button variant="secondary" @click="cancel()" class="mr-3">
              {{ $t('button.cancel') }}
          </b-button> 
          <b-button variant="success" @click="updateContributor(selectedContributor)">
            {{ $t('mealsystem.contributors.button.save_contributor') }}
          </b-button>         
        </template>
    </b-modal>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  data() {
    return {
      loading: false,
      contributors: null,
      selectedContributor: {},
      page: 1,
      search: "",
    };
  },
  components: {

  },
  beforeMount() {
    this.getContributors();
  },
  metaInfo () { 
      return { title: this.$t("mealsystem.meta.contributors_list")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("mealsystem.meta.contributors"), subtitle: this.$t("mealsystem.meta.contributors_list") }]);
  },
  computed: {
  
  },
  methods: {
    getContributors() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/contributors?search=" + this.search)
        .then((response) => {
          this.contributors = response.data;
          this.loading = false;
        });
    },
    setContributor(contributor){
    	this.selectedContributor = contributor;
    },
    loadContributor(contributor){
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/contributors/" + contributor.contributor_id)
        .then((response) => {
          this.selectedContributor = response.data.data;
        }).catch(error => {
            checkErrors(error);
      });
    },
    usetContributor(){
      this.selectedContributor = {
        name: ""
      }
    },
    deleteContributor(contributor){
      this.loading = true;
      ApiService.setHeader();      
      ApiService.apiPost("/meal-system/contributors/" + contributor.contributor_id + "/delete")
        .then(() => {
            this.loading = false;
            this.$bvModal.hide('modal-contributor-delete');
            this.getContributors();
        }).catch(error => {
            checkErrors(error);
      });
    },
    updateContributor(contributor){
      this.loading = true;
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          return;
        } 

        if(contributor.contributor_id){
          this.endpoint = "/meal-system/contributors/" + contributor.contributor_id + "/update"
        } else {
          this.endpoint  = "/meal-system/contributors";
        }

        ApiService.setHeader();      
        ApiService.apiPost(this.endpoint,{
          'name': contributor.name,
          'in': contributor.in,
          'tin': contributor.tin,
          'phone': contributor.phone,
          'email': contributor.email,
          'street': contributor.street,
          'house_number': contributor.house_number,
          'city': contributor.city,
          'zip': contributor.zip,
        })
          .then(() => {
              this.loading = false;
              this.$bvModal.hide('modal-contributor-edit');
              this.getContributors();
          }).catch(error => {
              checkErrors(error);
        });
      });
    },
    searchContributors(){
      this.page = 1;
      this.getContributors(); 
      this.contributorSearch = {
        search: this.search
      } 
    },
    removeFilters(){
      this.page = 1;
      this.search = "";
      this.contributorSearch = {
        search: this.search
      };
      this.getContributors(); 
    },
    loadAresData() {
      const submitButton = this.$refs["load_ares"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/ares/" + this.selectedContributor.in)
        .then((response) => {
          this.selectedContributor.name = response.data.ares.company;
          this.selectedContributor.tin = response.data.ares.tin;
          this.selectedContributor.street = response.data.ares.street;
          this.selectedContributor.house_number = response.data.ares.house_number;
          this.selectedContributor.city = response.data.ares.city;
          this.selectedContributor.zip = response.data.ares.zip;
          this.loading = false;
          submitButton.classList.remove("spinner","spinner-light","spinner-right");
        })
        .catch(() => {
          this.$toasted.error('Nastala chyba.')
          this.loading = false;
          submitButton.classList.remove("spinner","spinner-light","spinner-right");
        })
    },
  }
};
</script>
